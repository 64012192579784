<template>
  <Dialog
    v-if="
      $parent.activeClient.dni &&
      currentPlayData &&
      currentPlayData.plays.length
    "
    class="no_close_button"
    :style="{ width: '450px' }"
    header="Confirmar"
    :modal="true"
    :closable="true"
    :closeOnEscape="true"
  >
    <div class="flex align-items-center justify-content-center">
      <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
      <span
        >Hay una jugada sin procesar 
¿Está seguro de anular esta jugada?</span
      >
    </div>
    <template #footer>
      <Button
        label="No"
        icon="pi pi-times"
        class="p-button-text"
        @click="setClientDialog(false)"
      />
      <Button
        label="Sí"
        icon="pi pi-check"
        class="p-button-text"
        @click="deleteCurrentPlay"
      />
    </template>
  </Dialog>
  <Dialog
    :header="!consult_mode ? 'Registrar Cliente' : 'Consultar'"
    :modal="true"
    :style="{ width: '500px', maxHeight: '95vh' }"
    class="p-fluid dialog-consult no_close_button"
    @show="onShow"
    v-else-if="
      ticketofficeOnLine || ['taquilla'].includes($store.getters['auth/role'])
    "
    :closable="true"
    :closeOnEscape="true"
  >
    <div class="card w-full mt-2">
      <form
        class="w-full mt-0"
        autocomplete="off"
        aria-autocomplete="off"
        id="person_register"
        @submit.prevent="handleSubmit(!v$.$invalid)"
      >
        <div class="formgrid grid mb-3">
          <div class="field col-12">
            <div class="p-float-label">
              <Dropdown
                v-model="user.country"
                :disabled="!consult_mode"
                :options="countries"
                optionValue="id"
                optionLabel="name"
                :filter="false"
                :showClear="false"
                class="w-full"
                style="min-height: 47px"
                :class="{
                  'p-invalid':
                    v$.user.country && v$.user.country.$invalid && submitted,
                }"
              >
                <template #value="slotProps">
                  <div
                    class="country-item country-item-value"
                    v-if="slotProps.value"
                  >
                    <img
                      :src="
                        this.countries.find((e) => e.id == slotProps.value)
                          ? this.countries.find((e) => e.id == slotProps.value)
                              .flag
                          : ''
                      "
                    />
                    <div>
                      {{
                        this.countries.find((e) => e.id == slotProps.value)
                          ? this.countries.find((e) => e.id == slotProps.value)
                              .name
                          : ""
                      }}
                    </div>
                  </div>
                  <span v-else>
                    {{ slotProps.placeholder }}
                  </span>
                </template>
                <template #option="slotProps">
                  <div class="country-item">
                    <img :src="slotProps.option.flag" />
                    <div>{{ slotProps.option.name }}</div>
                  </div>
                </template>
              </Dropdown>
              <label for="profile_user_counry">País de Residencia *</label>
            </div>
            <small
              v-if="
                v$.user.country &&
                ((v$.user.country.$invalid && submitted) ||
                  v$.user.country.$pending.$response)
              "
              class="p-error"
              >Campo requerido.</small
            >
          </div>
        </div>
        <div class="formgrid grid">
          <div class="field col-12">
            <div class="p-inputgroup phone-inputgroup">
              <!-- <span
                class="operador-imput p-inputgroup-addon p-inputgroup-addon-checkbox"
                style="max-width: 75px"
                :class="{
                  'p-error': v$.user.dni.$invalid && submitted,
                }"
              >
                <span class="p-float-label">
                  <Dropdown
                    id="dni_code"
                    v-model="v$.user.dni_code.$model"
                    class="operador-imput w-full"
                    :options="['V', 'E']"
                    :disabled="!consult_mode"
                  />
                </span>
              </span> -->
              <span class="p-float-label">
                <InputText
                  id="dni"
                  v-model.trim="v$.user.dni.$model"
                  class="operador-imput"
                  :class="{
                    'p-invalid': v$.user.dni.$invalid && submitted,
                  }"
                  autocomplete="off"
                  aria-autocomplete="off"
                  autofocus
                  :disabled="!consult_mode"
                  @paste.prevent
                  @keydown.enter="handleSubmit(!v$.$invalid)"
                  @keypress="restrictChartsNumber($event)"
                  inputmode="numeric"
                  pattern="[0-9]*"
                  maxlength="15"
                />
                <label
                  class="operador-imput-title"
                  for="dni"
                  :class="{
                    'p-error': v$.user.dni.$invalid && submitted,
                  }"
                  >Documento de Identidad *</label
                >
              </span>
            </div>
            <small
              v-if="
                (v$.user.dni.$invalid && submitted) ||
                v$.user.dni.$pending.$response
              "
              class="p-error"
              >Ingrese el Número de Identidad.</small
            >
          </div>
        </div>
        <div class="formgrid grid" v-if="!consult_mode">
          <div class="field col-12">
            <div class="p-float-label">
              <InputText
                id="firstname"
                v-model.trim="v$.user.firstname.$model"
                class="operador-imput w-full"
                :class="{
                  'p-invalid': v$.user.firstname.$invalid && submitted,
                }"
                autocomplete="off"
                aria-autocomplete="off"
                autofocus
                @keypress="restrictCharts($event)"
                :maxlength="15"
                :minlength="2"
              />
              <label
                class="operador-imput-title"
                for="firstname"
                :class="{
                  'p-error': v$.user.firstname.$invalid && submitted,
                }"
                >Primer Nombre *</label
              >
            </div>
            <small
              v-if="
                (v$.user.firstname.$invalid && submitted) ||
                v$.user.firstname.$pending.$response
              "
              class="p-error"
              >Ingrese el primer nombre del cliente (sólo letras)</small
            >
          </div>
        </div>
        <div class="formgrid grid" v-if="!consult_mode">
          <div class="field col-12">
            <div class="p-float-label">
              <InputText
                id="lastname"
                v-model.trim="v$.user.lastname.$model"
                class="operador-imput w-full"
                :class="{
                  'p-invalid': v$.user.lastname.$invalid && submitted,
                }"
                autocomplete="off"
                aria-autocomplete="off"
                @keypress="restrictCharts($event)"
                :maxlength="15"
                :minlength="2"
              />
              <label
                class="operador-imput-title"
                for="lastname"
                :class="{
                  'p-error': v$.user.lastname.$invalid && submitted,
                }"
                >Primer Apellido *</label
              >
            </div>
            <small
              v-if="
                (v$.user.lastname.$invalid && submitted) ||
                v$.user.lastname.$pending.$response
              "
              class="p-error"
              >Ingrese el primer apellido del cliente (sólo letras)</small
            >
          </div>
        </div>
        <div class="formgrid grid" v-if="!consult_mode">
          <div class="field col-12">
            <div class="p-inputgroup phone-inputgroup">
              <span
                class="operador-cod operador-imput p-inputgroup-addon"
                style="max-width: 105px"
                :class="{
                  'p-error': v$.user.phone1_code.$invalid && submitted,
                }"
              >
                <span class="p-float-label">
                  <Dropdown
                    id="phone1_code"
                    ref="phone1_code"
                    v-model="v$.user.phone1_code.$model"
                    optionValue="id"
                    :options="countries"
                    :filter="false"
                    :showClear="false"
                    @change="setInputFocus('phone_principal')"
                    class="w-full"
                    style="min-height: 47px"
                    :filterFields="['name', 'callingCode']"
                  >
                    <template #value="slotProps">
                      <div
                        class="country-item country-item-value"
                        v-if="slotProps.value"
                      >
                        <img
                          :src="
                            this.countries.find((e) => e.id == slotProps.value)
                              ? this.countries.find(
                                  (e) => e.id == slotProps.value
                                ).flag
                              : ''
                          "
                        />
                        <div>
                          +{{
                            this.countries.find((e) => e.id == slotProps.value)
                              ? this.countries.find(
                                  (e) => e.id == slotProps.value
                                ).callingCode
                              : ""
                          }}
                        </div>
                      </div>
                      <span v-else>
                        {{ slotProps.placeholder }}
                      </span>
                    </template>
                    <template #option="slotProps">
                      <div class="country-item">
                        <img :src="slotProps.option.flag" />
                        <div>
                          +{{ slotProps.option.callingCode }}
                          {{ slotProps.option.name }}
                        </div>
                      </div>
                    </template>
                  </Dropdown>
                  <label
                    class="operador-imput-title"
                    for="phone1_code"
                    :class="{
                      'p-error': v$.user.phone1_code.$invalid && submitted,
                    }"
                    >Cód.</label
                  >
                </span>
              </span>
              <span class="p-float-label">
                <InputMask
                  id="phone_principal"
                  v-model.trim="v$.user.phone1.$model"
                  class="operador-imput"
                  mask="99999999999"
                  :unmask="true"
                  :autoClear="false"
                  :slotChar="''"
                  :class="{
                    'p-invalid': v$.user.phone1.$invalid && submitted,
                  }"
                  autocomplete="off"
                  aria-autocomplete="off"
                  inputmode="numeric"
                  pattern="[0-9]*"
                  @keydown.enter="handleSubmit(!v$.$invalid)"
                  @click="
                    ($event) =>
                      $event.target.setSelectionRange(
                        0,
                        $event.target.value.length
                      )
                  "
                />
                <label
                  class="operador-imput-title"
                  for="phone_principal"
                  :class="{
                    'p-error': v$.user.phone1.$invalid && submitted,
                  }"
                  >Teléfono Celular *</label
                >
              </span>
            </div>
            <small
              v-if="
                (v$.user.phone1_code.$invalid && submitted) ||
                v$.user.phone1_code.$pending.$response ||
                (v$.user.phone1.$invalid && submitted) ||
                v$.user.phone1.$pending.$response
              "
              class="p-error"
              >Ingrese el Código y el Número del Teléfono.</small
            >
          </div>
        </div>
        <div class="formgrid grid" v-if="!consult_mode">
          <div class="field col-12">
            <div class="p-float-label">
              <InputText
                name="email"
                v-model.trim="v$.user.email.$model"
                class="operador-imput w-full"
                autocomplete="off"
                aria-autocomplete="off"
                :class="{
                  'p-invalid': v$.user.email.$invalid && submitted,
                }"
              />
              <label
                class="operador-imput-title"
                for="email"
                :class="{
                  'p-error': v$.user.email.$invalid && submitted,
                }"
                >Email *</label
              >
            </div>
            <span v-if="v$.user.email.$error && submitted">
              <span
                id="email-error"
                v-for="(error, index) of v$.user.email.$errors"
                :key="index"
              >
                <small class="p-error">Ingrese un email válido.</small>
              </span>
            </span>
            <small
              v-else-if="
                (v$.user.email.$invalid && submitted) ||
                v$.user.email.$pending.$response
              "
              class="p-error"
              >Ingrese el email.</small
            >
          </div>
          <div class="field col-12 md:col-4"></div>
        </div>
      </form>
    </div>
    <template #footer>
      <Button
        label="Cancelar"
        icon="pi pi-times"
        class="p-button-text"
        @click="onCancel"
        :disabled="is_processing"
        v-if="
          (consult_mode && activeClient && activeClient.dni) || !consult_mode
        "
      />
      <Button
        :label="!consult_mode ? 'Guardar' : 'Consultar'"
        form="person_register"
        type="submit"
        icon="pi pi-check"
        class="p-button-text"
        :loading="is_processing"
      />
    </template>
  </Dialog>
</template>
<script>
import HTTP from "@/service/http";
import { mapGetters, mapMutations, mapActions, mapState } from "vuex";
import { required, email, requiredIf } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
const valid_letters = /^[a-zA-ZñÑáéíóú]+(\s[a-zA-ZñÑáéíóú]+)?$/;

export default {
  setup: () => ({ v$: useVuelidate() }),
  props: {},
  data() {
    return {
      is_processing: false,
      submitted: false,
      user: {},
      consult_mode: true,
      countries: [],
    };
  },
  created() {
    // this.fetchCountryCode();
    this.getDirectionsCountries();
  },
  methods: {
    ...mapActions("ticketoffice", ["cancelPlayOrder"]),
    // ...mapActions("configurations", ["fetchCountryCode"]),
    ...mapMutations("ticketoffice", [
      "setActiveClient",
      "clearCurrentPlayData",
      "saveTicketofficeSession",
      "setClientDialog",
      "setToggleTicketPreview",
    ]),
    restrictCharts($event) {
      if (
        [32, 39, 193, 201, 205].includes($event.charCode) ||
        ($event.charCode >= 65 && $event.charCode <= 90) ||
        ($event.charCode >= 97 && $event.charCode <= 122) ||
        ($event.charCode >= 129 && $event.charCode <= 160) ||
        ($event.charCode >= 208 && $event.charCode <= 250)
      ) {
        return true;
      }

      $event.preventDefault();
    },
    restrictChartsNumber($event) {
      if ($event.charCode >= 48 && $event.charCode <= 57) {
        return true;
      }
      $event.preventDefault();
    },
    onShow() {
      this.user = {};
      this.submitted = false;
      this.is_processing = false;
      this.user.dni_code = "V";
      this.consult_mode = true;
      this.setInputFocus("dni");
      this.setCountryUser();
    },
    onCancel() {
      if (!this.consult_mode && !this.$parent.activeClient.dni) {
        this.consult_mode = true;
        this.setInputFocus("dni");
      } else this.$emit("cancel");
    },
    handleSubmit(isFormValid) {
      var only_number = /^\d+$/;
      if (!only_number.test(this.user.dni)) {
        return;
      }
      this.submitted = true;
      if (!isFormValid) {
        this.$nextTick(() => this.findFirstError());
        return;
      }
      this.is_processing = true;
      if (this.consult_mode) this.consultClientData();
      else this.registerClientData();
    },
    consultClientData() {
      this.$store
        .dispatch("ticketoffice/consultClientData", this.user)
        .then((resp) => {
          this.$emit("complete", resp.data.data);
        })
        .catch((err) => {
          this.$toast.add({
            severity: "info",
            // severity: "warning",
            summary: "El DNI no se encuentra registrado.",
            // detail: err.response.data.errors[0].detail,
            life: 4000,
          });
          if (
            err.response.data.errors[0].detail
              .toLowerCase()
              .includes("no encontrado")
          ) {
            this.user.phone1_code = this.user.country;
            this.consult_mode = false;
            this.setInputFocus("firstname");
          }
        })
        .finally(() => {
          this.is_processing = false;
          this.submitted = false;
        });
    },
    registerClientData() {
      let data = {
        first_name: this.user.firstname,
        last_name: this.user.lastname,
        dni: this.user.dni,
        dni_prefix: this.user.dni_code,
        fk_contact: {},
        fk_direction: {},
      };
      if (this.user.email) {
        data.fk_contact.email = this.user.email;
      }
      if (this.user.phone1_code && this.user.phone1) {
        this.user.phone1 = this.user.phone1.replace(/^0+/, "");
        data.fk_contact.fk_phone_number_code = this.user.phone1_code;
        data.fk_contact.phone_number = this.user.phone1;
      }
      if (this.user.country) {
        data.fk_direction.fk_country = this.user.country;
      }
      this.$store
        .dispatch("ticketoffice/registerClientData", data)
        .then((resp) => {
          this.$toast.add({
            severity: "success",
            detail: "Datos Registrados con Exito.",
            life: 4000,
          });
          this.$emit("complete", resp.data.data);
        })
        .catch((err) => {
          if (err.response)
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: this.getFirstError(err.response.data.errors),
              life: 4000,
            });
        })
        .finally(() => {
          this.is_processing = false;
          this.submitted = false;
        });
    },
    findFirstError() {},
    setDropdownFocus(field_name) {
      setTimeout(() => {
        let input = document.getElementById(field_name);
        if (input) {
          input.getElementsByTagName("input")[1].focus();
        }
      }, 60);
    },
    setInputFocus(field_name) {
      setTimeout(() => {
        let input = document.getElementById(field_name);
        if (input) {
          input.focus();
          input.scrollIntoView(true);
        }
      }, 60);
    },
    deleteCurrentPlay() {
      this.cancelPlayOrder(this.currentPlayData.ticket.id)
        .then(() => {
          this.setActiveClient({});
          this.setToggleTicketPreview(false);
          this.clearCurrentPlayData();
          this.saveTicketofficeSession();
          this.$toast.add({
            severity: "success",
            summary: "Orden Anulada.",
            detail: "",
            life: 4000,
          });
        })
        .catch((err) => {
          if (err.response) {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: err.response.data.errors[0].detail,
              life: 4000,
            });
          }
        });
    },
    getDirectionsCountries() {
      HTTP(true)
        .get("/directions/countries")
        .then((resp) => {
          this.countries = resp.data.data.map((e) => {
            return {
              id: e.id,
              name: e.name,
              flag: e.flag,
              callingCode: e.phonecod,
              tld: e.tld,
            };
          });
          this.setCountryUser();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    setCountryUser() {
      const default_country = this.countries.find(
        (e) => e.tld.toLowerCase() === this.country_code.toLowerCase()
      );

      if (default_country) {
        this.user.country = default_country.id;
        this.user.phone1_code = default_country.id;
      }
    },
  },
  computed: {
    ...mapGetters("ticketoffice", ["activeClient", "currentPlayData"]),
    ...mapState("ticketoffice", ["ticketofficeOnLine"]),
    ...mapState("configurations", ["country_code"]),
  },
  validations() {
    return {
      user: {
        dni: {
          required,
        },
        dni_code: {
          required,
        },
        firstname: {
          required: requiredIf(function () {
            return !this.consult_mode;
          }),
          alphaOnly: (value) => {
            return this.consult_mode || valid_letters.test(value);
          },
        },
        lastname: {
          required: requiredIf(function () {
            return !this.consult_mode;
          }),
          alphaOnly: (value) => {
            return this.consult_mode || valid_letters.test(value);
          },
        },
        phone1_code: {
          required: requiredIf(function () {
            return (
              !this.consult_mode && this.user.phone1 && this.user.phone1 !== ""
            );
          }),
        },
        phone1: {
          required: requiredIf(function () {
            return (
              !this.consult_mode 
            );
          }),
        },
        country: {
          required: requiredIf(function () {
            return !this.consult_mode;
          }),
        },
        email: {
          email,
          required: requiredIf(function () {
            return !this.consult_mode && this.user.email && this.user.email !== "";
          }),
        },
      },
    };
  },
};
</script>
<style scoped lang="scss">
.phone-inputgroup {
  .p-inputgroup-addon {
    padding: 0;
    width: 145px;
  }
  .p-dropdown {
    border: 0px;
  }

  .p-inputgroup-addon.p-error {
    border-color: #b00020;
  }
  .p-dropdown :deep(.p-dropdown-trigger) {
    width: 1.8rem !important;
  }
}
.country-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.country-item {
  img {
    width: 17px;
    margin-right: 0.5rem;
  }
}
</style>
